<template>
  <div>
    <slot />
    <vz-overlay />
  </div>
</template>

<script setup lang="ts">
import { useSeoStore } from '~/stores/seo'
import {useHeaders} from '~/composables/states'
import VzOverlay from '~/components/ui-kit/vz-overlay.vue'

const seoStore = useSeoStore()

useHead({
  title: seoStore.seo?.seo?.title || 'Транспортная компания «Возовоз»',
  meta: seoStore.seo?.seo?.meta,
  link: seoStore.seo?.seo?.canonical ? [{ rel: 'canonical', href: `${seoStore.seo?.seo?.canonical}` }] : undefined,
})
const headers = useHeaders()
</script>
